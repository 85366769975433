import React from 'react';
import { observer, inject } from 'mobx-react';
import CategoryCard from './CategoryCard';

import onBaseHousing1x from 'categories/military-homes-300x200.jpg';
import onBaseHousing2x from 'categories/military-homes-600x400.jpg';
import neighborhoods1x from 'categories/neighborhoods-300x200.jpg';
import neighborhoods2x from 'categories/neighborhoods-600x400.jpg';
import apartments1x from 'categories/apartments-300x200.jpg';
import apartments2x from 'categories/apartments-600x400.jpg';
import movingCompanies1x from 'categories/movers-300x200.jpg';
import movingCompanies2x from 'categories/movers-600x400.jpg';
import lenders1x from 'categories/lenders-300x200.jpg';
import lenders2x from 'categories/lenders-600x400.jpg';
import realtors1x from 'categories/realtors-300x200.jpg';
import realtors2x from 'categories/realtors-600x400.jpg';

@inject('uiState')
@observer
class Categories extends React.PureComponent {
  render() {
    const { uiState } = this.props;

    const categories = {
      neighborhood: (
        <>
          <img
            className="category-card__image"
            alt="Row of houses in a neighborhood"
            srcSet={`${neighborhoods1x}, ${neighborhoods2x} 2x`}
            src={neighborhoods1x}
          />
          <div className="category-card__content">
            <p className="fs-lg">Off-Base Neighborhoods</p>
          </div>
        </>
      ),
      on_base_housing: (
        <>
          <img
            className="category-card__image"
            alt="Military House"
            srcSet={`${onBaseHousing1x}, ${onBaseHousing2x} 2x`}
            src={onBaseHousing1x}
          />
          <div className="category-card__content">
            <p className="fs-lg">On Base Housing</p>
          </div>
        </>
      ),
      apartment: (
        <>
          <img
            className="category-card__image"
            alt="Apartment kitchen"
            srcSet={`${apartments1x}, ${apartments2x} 2x`}
            src={apartments1x}
          />
          <div className="category-card__content">
            <p className="fs-lg">Apartments</p>
          </div>
        </>
      ),
      realtor: (
        <>
          <img
            className="category-card__image"
            alt="Builder measuring lumber"
            srcSet={`${realtors1x}, ${realtors2x} 2x`}
            src={realtors1x}
          />
          <div className="category-card__content">
            <p className="fs-lg">Real Estate Agents</p>
          </div>
        </>
      ),
      lender: (
        <>
          <img
            className="category-card__image"
            alt="Mortgage lender"
            srcSet={`${lenders1x}, ${lenders2x} 2x`}
            src={lenders1x}
          />
          <div className="category-card__content">
            <p className="fs-lg">Mortgage Lenders</p>
          </div>
        </>
      ),
      moving_company: (
        <>
          <img
            className="category-card__image"
            alt="Large Moving Van"
            srcSet={`${movingCompanies1x}, ${movingCompanies2x} 2x`}
            src={movingCompanies1x}
          />
          <div className="category-card__content">
            <p className="fs-lg">Moving Companies</p>
          </div>
        </>
      ),
    };

    return (
      <>
        {uiState.cardOrder.map((category) => {
          return (
            <CategoryCard providerType={category} key={category} pcsOnly>
              {categories[category]}
            </CategoryCard>
          );
        })}
      </>
    );
  }
}

export default Categories;
