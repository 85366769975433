import { extendObservable } from 'mobx';

import RentVsBuyModel from './RentVsBuyModel';
import { createRentVsBuy } from '../../api/landing_pages/RentVsBuyApi';

class WizardStore {
  constructor() {
    this.rentVsBuyStore = new RentVsBuyModel();
    extendObservable(this, {
      currentStep: 1
    });
  }

  onNextStep = () => {
    this.rentVsBuyStore.validate(this.currentStep);

    if (this.rentVsBuyStore.noErrors) {
      this.currentStep = this.currentStep + 1;
    }
  };

  onLastStep = () => {
    this.rentVsBuyStore.clearErrors();
    this.currentStep = this.currentStep - 1;
  };

  onComplete = () => {
    createRentVsBuy(this.rentVsBuyStore.formAttributes, response => {
      this.rentVsBuyStore.clearErrors();
      this.rentVsBuyStore.convertErrors(Object.entries(response.errors));
    });
  };
}

export default new WizardStore();
