import React from 'react';

import WizardStore from './stores/WizardStore';
import { StoreProvider } from '../utils/store';
const WizardContent = React.lazy(() =>
  import(/* webpackPreload: true */ './components/WizardContent')
);

const ExternalLeadWizard = (props) => {
  const wizardStore = new WizardStore(props);
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <StoreProvider store={wizardStore}>
        <WizardContent />
      </StoreProvider>
    </React.Suspense>
  );
};

export default ExternalLeadWizard;
