import React from 'react';

import RebateStore from './stores/RebateStore';
const EstimatorContent = React.lazy(() =>
  import('./components/EstimatorContent')
);

const RebateEstimator = _railsProps => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <EstimatorContent store={RebateStore} />
    </React.Suspense>
  );
};

export default RebateEstimator;
