import React from 'react';

import CalculatorContent from './components/CalculatorContent';
import CalculatorStore from './stores/CalculatorStore';

const RebateCalculator = (props) => {
  const store = new CalculatorStore(props);
  return <CalculatorContent store={store} />;
};

export default RebateCalculator;
