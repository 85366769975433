import 'react-datepicker/dist/react-datepicker.css';

import ComponentRegister from '../utils/ComponentRegister';
import RebateCalculator from '../bundles/RebateCalculator';
import RebateEstimator from '../bundles/RebateEstimator';
import RebatesWizard from '../bundles/RebatesWizard';
import ExternalLeadWizard from '../bundles/ExternalLeadWizard';
import RentVsBuy from '../bundles/RentVsBuy';
import StreamVideo from '../bundles/StreamVideo';
import ReviewCategories from '../bundles/ReviewCategories';

ComponentRegister.setup({
  RebateCalculator,
  RebateEstimator,
  RebatesWizard,
  RentVsBuy,
  StreamVideo,
  ReviewCategories,
  ExternalLeadWizard,
});
