function rebateConverter(value) {
  if (value === '') {
    return 0;
  } else if (value < 126482) {
    return '400';
  } else if (value < 2845850) {
    return Math.round(value * 0.115 * 0.0275).toString();
  } else {
    return '9000';
  }
}

export default rebateConverter;
