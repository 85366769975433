import appStruct from '../../../utils/appStruct';
import ErrorDictionary from '../../../utils/ErrorDictionary';

export const stepTwoSchema = appStruct.pick({
  movingDate: 'date',
  area: 'string & not_empty'
});

export const stepThreeSchema = appStruct.pick({
  monthlyPayment: 'string & money',
  downPayment: 'string & money'
});

export const stepFourSchema = appStruct.pick({
  loanPeriod: appStruct.enum([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10+'
  ])
});

export const stepFiveSchema = appStruct.pick({
  firstName: 'string & not_empty',
  lastName: 'string & not_empty',
  phoneNumber: 'string & not_empty'
});

export const errorDictionary = new ErrorDictionary({
  movingDate: 'Moving Date is required',
  area: 'City/State is required',
  monthlyPayment: 'Monthly Payment is required',
  downPayment: 'Down Payment is required',
  firstName: 'First Name is required',
  lastName: 'Last Name is required',
  phoneNumber: 'Phone Number is required',
  loanPeriod: 'Must select how many years you plan on living here'
});
