import authenticityHeaders from '../../../utils/authenticityHeaders';

export const createRentVsBuy = (params, callback) => {
  const headersInit = {
    'Content-Type': 'application/json',
    'Accepts': 'application/json, text/html'
  };
  const requestInit = {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({
      rent_vs_buy: params
    }),
    headers: authenticityHeaders(headersInit)
  };

  return fetch('/rent-vs-buy', requestInit)
    .then(response => {
      if (response.redirected) {
        window.location = response.url;
      } else {
        return response.json();
      }
    })
    .then(response => callback(response));
};
