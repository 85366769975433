import { extendObservable } from 'mobx';

import * as validations from '../validations/rentVsBuy';

class RentVsBuyModel {
  constructor(attributes = {}) {
    this.validationSteps = {
      2: validations.stepTwoSchema,
      3: validations.stepThreeSchema,
      4: validations.stepFourSchema,
      5: validations.stepFiveSchema
    };

    extendObservable(this, {
      values: {
        movingDate: attributes.moving_date,
        area: attributes.area || '',
        monthlyPayment: attributes.monthly_payment || '',
        downPayment: attributes.down_payment || '',
        firstName: attributes.first_name || '',
        lastName: attributes.last_name || '',
        email: attributes.email || '',
        password: attributes.password || '',
        loanPeriod: attributes.loan_period || '',
        phoneNumber: attributes.phone_number || ''
      },
      errors: {},
      get noErrors() {
        return Object.keys(this.errors).length === 0;
      },
      get formAttributes() {
        return {
          moving_date: this.values.movingDate,
          area: this.values.area,
          monthly_payment: this.values.monthlyPayment,
          down_payment: this.values.downPayment,
          loan_period: this.values.loanPeriod,
          phone_number: this.values.phoneNumber,
          member_attributes: {
            first_name: this.values.firstName,
            last_name: this.values.lastName,
            email: this.values.email,
            password: this.values.password
          }
        };
      }
    });
  }

  handleChangeRaw = fieldName => event => {
    this.values[fieldName] = event.target.value;
  };

  handleChange = fieldName => newValue => {
    this.values[fieldName] = newValue;
  };

  validate = stepNumber => {
    let errors = {};

    const [error] = this.checkValues(stepNumber);
    if (error) {
      errors = validations.errorDictionary.formatFailures(error.failures);
    }

    this.errors = errors;
  };

  checkValues = stepNumber => {
    const validationSchema = this.validationSteps[stepNumber];
    if (validationSchema) {
      return validationSchema.validate(this.values);
    } else {
      return [];
    }
  };

  clearErrors = () => {
    this.errors = {};
  };

  addError = (key, value) => {
    this.errors[key] = value;
  };

  convertErrors = entries => {
    entries.map(([key, value]) => {
      this.addError(key, value);
    });
  };
}

export default RentVsBuyModel;
