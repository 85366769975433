import React from 'react';
import PropTypes from 'prop-types';
import { Stream } from '@cloudflare/stream-react';

/**
 * @see {@link https://github.com/cloudflare/stream-react}
 */
const StreamVideo = function StreamVideo(props) {
  const { video_id, ...streamProps } = props;
  return <Stream src={video_id} {...streamProps} />;
};

StreamVideo.defaultProps = {
  controls: true,
  className: '',
};

StreamVideo.propTypes = {
  video_id: PropTypes.string.isRequired,
  className: PropTypes.string,
  ...Stream.propTypes,
};

export default StreamVideo;
