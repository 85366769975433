import { extendObservable } from 'mobx';

import { emailIsValid, zipCodeIsValid } from '../../../utils/formatValidators';
import { externalConciergeRequestObject } from '../../utils/externalConciergeRequestObject';

class ConciergeRequestModel {
  constructor(attributes = {}) {
    this.validations = {
      3: this.validateStepThree,
      4: this.validateStepFour,
      5: this.validateStepFive,
    };

    extendObservable(this, externalConciergeRequestObject(attributes));
  }

  handleChangeRaw = (fieldName) => (event) => {
    this.values[fieldName] = event.target.value;
  };

  handleChange = (fieldName) => (newValue) => {
    this.values[fieldName] = newValue;
  };

  handleListedAddressChangeRaw = (fieldName) => (event) => {
    this.values.listedAddressAttributes[fieldName] = event.target.value;
  };

  handleListedAddressChange = (fieldName) => (newValue) => {
    this.values.listedAddressAttributes[fieldName] = newValue;
  };

  handleFullNameChange = (event) => {
    this.values.fullName = event.target.value;
    let fullNameArray = [];
    if (/\s/g.test(this.values.fullName)) {
      fullNameArray = this.values.fullName.split(' ');
    }
    this.values.firstName = fullNameArray[0];
    this.values.lastName = fullNameArray[1];
  };

  validate = (stepNumber) => {
    const validation = this.validations[stepNumber];
    if (validation) {
      validation();
    }
  };

  validateStepThree = () => {
    if (this.values.area === '') {
      this.errors.area = 'Military Base or City is required';
    }

    if (this.values.destinationState === '') {
      this.errors.state = 'State is required';
    }
  };

  validateStepFour = () => {
    if (this.values.listedAddressAttributes.street === '') {
      this.errors.street = 'Street is required';
    }

    if (this.values.listedAddressAttributes.city === '') {
      this.errors.sellCity = 'City is required';
    }

    if (this.values.listedAddressAttributes.state === '') {
      this.errors.sellState = 'State is required';
    }

    if (!zipCodeIsValid(this.values.listedAddressAttributes.postalCode)) {
      this.errors.postalCode = 'Postal Code Must be 5 Digits';
    }
  };

  validateStepFive = () => {
    if (this.values.lastName === '' || this.values.lastName === undefined) {
      this.errors.lastName = 'Full Name is required';
    }

    if (this.values.email === '') {
      this.errors.email = 'Email is required';
    } else if (!emailIsValid(this.values.email)) {
      this.errors.email = 'Email is not valid';
    }

    if (this.values.phoneNumber === '') {
      this.errors.phoneNumber = 'Phone Number is required';
    }
  };

  clearErrors = () => {
    this.errors = {};
  };

  addError = (key, value) => {
    this.errors[key] = value;
  };

  convertErrors = (entries) => {
    entries.map(([key, value]) => {
      this.addError(key, value);
    });
  };
}

export default ConciergeRequestModel;
