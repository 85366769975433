import { extendObservable } from 'mobx';

import rebateConverter from '../../utils/rebateConverter';

class CalculatorStore {
  constructor(props) {
    extendObservable(this, {
      value: props.value || '',
      convertedValue: this.convertedRebate,
      formSubmitted: true,
      get convertedRebate() {
        if (this.formSubmitted) {
          return rebateConverter(this.value);
        } else {
          return '';
        }
      },
    });
  }

  handleChange = ({ value }) => {
    if (!isNaN(value)) {
      this.value = value;
    }
  };
}

export default CalculatorStore;
