import React from 'react';
import { Provider } from 'mobx-react';
import Categories from './components/Categories';
import UiState from './stores/UiState';

const ReviewCategories = railsProps => {
  const uiState = new UiState(railsProps);
  return (
    <Provider uiState={uiState}>
      <Categories />
    </Provider>
  );
};

export default ReviewCategories;
