import React from 'react';
import NumberFormat from 'react-number-format';
import { observer } from 'mobx-react-lite';
import { useCountUp } from 'react-countup';

const CalculatorContent = observer(function CalculatorContent({ store }) {
  const { countUp, update } = useCountUp({
    start: store.convertedRebate,
    end: 0,
    delay: 1000,
    duration: 0.8,
    separator: ',',
  });

  return (
    <>
      <div className="rebate-section w-full" id="rebate-calc">
        {/* CALC SECTION */}
        <div className="calc-section w-full text-center white-text">
          <div className="calc-form text-center white-text w-full p-4">
            <h3>Calculate Your Reward</h3>

            {/* PURCHASE PRICE INPUT */}
            <div
              className="ui labeled small fluid input rebate-input m-auto"
              id="purchase-price-div"
            >
              <NumberFormat
                type="text"
                value={store.value}
                onValueChange={store.handleChange}
                placeholder="Enter Purchase Price"
                aria-label="Purchase Price"
                thousandSeparator={true}
                onChange={() => update(parseInt(store.convertedRebate))}
              />
            </div>

            {/* ESTIMATED REBATE AMOUNT */}
            <div className="mt-4">
              <p className="fs-2xl font-semibold mb-1">=${countUp}</p>
              <p>Cash Back*</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CalculatorContent;
