class ErrorDictionary {
  constructor(messages) {
    this.messages = messages;
  }

  formatFailures = (failures) => {
    const errors = {};
    failures.map(({ path }) => {
      errors[path[0]] = this.getMessage(path[0]);
    });
    return errors;
  };

  getMessage = (attribute) => {
    return this.messages[attribute];
  };
}

export default ErrorDictionary;
