import React from 'react';
import { inject, observer } from 'mobx-react';
import { Card, CardPrimaryAction } from '@rmwc/card';

@inject('uiState')
@observer
class CategoryCard extends React.PureComponent {
  render() {
    const { children, mvcOnly, pcsOnly, uiState } = this.props;

    if (mvcOnly && uiState.currentUiVersion !== 'mvc_awards') {
      return null;
    }

    if (pcsOnly && uiState.currentUiVersion !== 'default') {
      return null;
    }

    let onCardClick = this.onCardClick;
    if (uiState.external) {
      onCardClick = this.onExternalCardClick;
    }

    return (
      <Card className="category-card m-4 p-2">
        <CardPrimaryAction className="pb-2" onClick={onCardClick}>
          {children}
        </CardPrimaryAction>
      </Card>
    );
  }

  onCardClick = (_event) => {
    const providerType = this.props.providerType;
    window.location = `/members/pick-a-provider/${providerType}`;
  };

  onExternalCardClick = (_event) => {
    const providerType = this.props.providerType;
    let utmContentName = providerType;
    if (this.props.utmContent) {
      utmContentName = this.props.utmContent;
    }
    window.location = `/members/sign_up/${providerType}?utm_source=${this.props.uiState.utmSource}&utm_medium=website&utm_content=${utmContentName}`;
  };
}

export default CategoryCard;
