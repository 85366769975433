import { extendObservable } from 'mobx';

class UiState {
  constructor(railsProps) {
    extendObservable(this, {
      currentUiVersion: railsProps.current_ui_version || 'default',
      external: railsProps.external || false,
      utmSource: railsProps.utm_source || 'pcsgrades',
      cardOrder: railsProps.order || [
        'neighborhood',
        'on_base_housing',
        'apartment',
        'realtor',
        'lender',
        'moving_company',
      ],
    });
  }
}

export default UiState;
