import { superstruct } from 'superstruct';
import { emailIsValid, moneyIsValid } from './formatValidators';

const appStruct = superstruct({
  types: {
    email: (value) => emailIsValid(value),
    money: (value) => moneyIsValid(value),
    not_empty: (value) => value !== null && value !== '',
  },
});

export default appStruct;
