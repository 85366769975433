import React from 'react';

import WizardStore from './stores/WizardStore';
import { StoreProvider } from '../utils/store';
const Header = React.lazy(() =>
  import(/* webpackPreload: true */ './components/Header')
);

const RentVsBuy = _railsProps => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <StoreProvider store={WizardStore}>
        <Header />
      </StoreProvider>
    </React.Suspense>
  );
};

export default RentVsBuy;
