import { extendObservable } from 'mobx';

import { moneyIsValid } from '../../../utils/formatValidators';

class RebateStore {
  constructor() {
    extendObservable(this, {
      purchasePrice: '',
      rebateEstimate: undefined,
      submitted: false,
      errors: {},
      get noErrors() {
        return Object.keys(this.errors).length === 0;
      },
      get showEstimate() {
        return this.noErrors && this.submitted === true;
      },
      get humanizedEstimate() {
        if (this.rebateEstimate) {
          return this.rebateEstimate
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        } else {
          return '';
        }
      }
    });
  }

  validate = () => {
    let errors = {};

    if (!moneyIsValid(this.purchasePrice)) {
      errors.purchasePrice = 'Purchase Price Must Be formatted properly';
    }

    if (this.purchasePrice === '') {
      errors.purchasePrice = 'Purchase Price is Required';
    }

    this.errors = errors;
  };

  clearErrors = () => {
    this.errors = {};
  };

  updateEstimate = () => {
    this.rebateEstimate = this.purchasePrice * '.006';
  };

  handlePurchasePriceChange = newValue => {
    this.purchasePrice = newValue;
  };

  handleSubmit = () => {
    this.validate();

    if (this.noErrors) {
      this.updateEstimate();
    }

    this.submitted = true;
  };
}

export default new RebateStore();
