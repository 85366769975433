import { extendObservable } from 'mobx';

import ConciergeRequestModel from './ConciergeRequestModel';
import { createConciergeRequest } from '../../api/landing_pages/RebatesApi';

class WizardStore {
  constructor(props) {
    this.conciergeRequestStore = new ConciergeRequestModel(props);

    extendObservable(this, {
      currentStep: 1,
      availableSteps: [1, 2],
      stepIndex: 0,
    });
  }

  handleLookingToSelect = (value) => {
    if (value === 'buy') {
      this.availableSteps = [1, 2, 3, 5, 6];
    }

    if (value === 'sell') {
      this.availableSteps = [1, 2, 4, 5];
    }

    if (value === 'both') {
      this.availableSteps = [1, 2, 3, 4, 5, 6];
    }
    this.conciergeRequestStore.values.lookingTo = value;
    this.onNextStep();
  };

  handleNeedsLender = (value) => {
    this.conciergeRequestStore.values.needsLender = value;

    this.onNextStep();
  };

  onNextStep = () => {
    this.conciergeRequestStore.clearErrors();
    this.conciergeRequestStore.validate(this.currentStep);

    if (this.conciergeRequestStore.noErrors) {
      if (this.availableSteps.at(-1) === this.currentStep) {
        this.onComplete();
      } else {
        this.stepIndex += 1;
        this.currentStep = this.availableSteps[this.stepIndex];
      }
    }
  };

  onLastStep = () => {
    this.conciergeRequestStore.clearErrors();
    this.stepIndex -= 1;
    this.currentStep = this.availableSteps[this.stepIndex];
  };

  onComplete = () => {
    if (this.conciergeRequestStore.noErrors) {
      this.conciergeRequestStore.isLoading = true;

      createConciergeRequest(this.conciergeRequestStore.formAttributes).catch(
        (response) => {
          this.conciergeRequestStore.clearErrors();
          this.conciergeRequestStore.convertErrors(
            Object.entries(response.errors)
          );
          this.conciergeRequestStore.isLoading = false;
        }
      );
    }
  };
}

export default WizardStore;
